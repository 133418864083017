*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --bg-secondary: rgba(76, 110, 144, 0.2);
  --text-secondary: #fafafa;
  --text-light: #fafafaa0;
  --x-text-light: #bcbbbb74;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: transparent;
}
.skeleton-bg {
  background: rgba(82, 119, 156, 0.086);
  border-radius: 2px;
}

.line-skeleton {
  height: 10px;
  width: 100%;
}

.square-skeleton {
  height: 60px;
  aspect-ratio: 1/1;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error-message {
  font-size: 14px;
  color: rgb(242, 42, 42);
  margin: 10px 0;
}
.info-message {
  font-size: 14px;
  color: rgb(242, 142, 42);
}

.card {
  border-radius: 0.6rem;
  background-color: var(--bg-secondary);
  padding: 1rem 0.8rem;
}

.card-heading {
  font-weight: 800;
  font-size: 14px;
  color: var(--text-light);
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .card-heading {
    font-weight: 600;
    font-size: 12px;
  }
}
