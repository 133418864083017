.app {
  background-color: rgb(11, 19, 30);
  color: white;
  min-height: 100vh;
  padding: 1.2rem 3.5rem;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  padding-bottom: 2rem;
}

.weather-details-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  row-gap: 16px;
}

.five-day-forecast {
  width: 20rem;
}

@media only screen and (max-width: 768px) {
  .app {
    flex-direction: column;
    height: 100%;
    padding: 1.2rem 1.5rem;
  }
  .weather-details-wrapper {
    flex: none;
  }
}

@media only screen and (min-width: 480px) {
}
