.container {
  margin-left: auto;
  position: relative;
  width: 75px;
  display: inline-block;
  text-align: left;
  top: 0px;
  -webkit-tap-highlight-color: transparent;

  input[type="checkbox"] {
    display: none;
  }
  .label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
  }

  .inner {
    display: block;
    width: 200%;
    margin-left: -100%;
  }

  .inner:before,
  .inner:after {
    float: left;
    width: 50%;
    height: 36px;
    padding: 0;
    line-height: 36px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
  }
  .inner:before {
    content: attr(data-on-label);
    padding-left: 10px;
    background-color: #060;
    color: #fff;
  }
  .inner:after {
    content: attr(data-off-label);
    padding-right: 10px;
    background-color: #807c7c64;
    color: #fff;
    text-align: right;
  }
  .switch {
    display: block;
    width: 24px;
    height: 24px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: unset;
    border: 0 solid #bbb;
    border-radius: 50%;
    flex: none;
  }

  input[type="checkbox"]:checked + .label .inner {
    margin-left: 0;
  }
  input[type="checkbox"]:checked + .label .switch {
    right: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    width: 63px;
    .switch {
      height: 18px;
      width: 18px;
    }
    .inner:before,
    .inner:after {
      height: 28px;
      line-height: 31px;
      font-size: 14px;
    }

    .inner:before {
      padding-left: 5px;
    }
  }
}
